export type PhaseKind =
  | 'ESQ'
  | 'APS'
  | 'APD'
  | 'PRO'
  | 'ACT'
  | 'VISA'
  | 'EXE'
  | 'EXE1'
  | 'EXE2'
  | 'SYN'
  | 'DET'
  | 'AOR'
  | 'SSI'
  | 'DIAG'
  | 'EFA'
  | 'DQE'
  | 'RT'
  | 'SOUS TRAITANCE'
  | 'CONC'
  | 'ADM';

export const PhaseKinds: PhaseKind[] = [
  'DIAG',
  'ESQ',
  'APS',
  'APD',
  'PRO',
  'ACT',
  'VISA',
  'EXE',
  'EXE1',
  'EXE2',
  'SYN',
  'DET',
  'AOR',
  'SSI',
  'EFA',
  'DQE',
  'RT',
  'SOUS TRAITANCE',
  'CONC',
  'ADM',
];

export const DefaultPhaseKinds: PhaseKind[] = [
  'ESQ',
  'APS',
  'APD',
  'PRO',
  'ACT',
  'EXE',
  'DET',
  'AOR',
  'SSI',
];

export const PhaseKindSort = (k1: string, k2: string) =>
  !PhaseKinds.includes(k1 as PhaseKind) && !PhaseKinds.includes(k2 as PhaseKind)
    ? k1.localeCompare(k2)
    : PhaseKinds.indexOf(k1 as PhaseKind) - PhaseKinds.indexOf(k2 as PhaseKind);
